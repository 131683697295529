const CONSTANT = {
  header: {
    sibone: {
      menuTrigger: 'sb-header__menu-trigger',
      navLinks: 'sibone-nav-links',
    },
  },
  table: {
    sbTableTrigger: 'sibone-table-trigger',
    sbTableContainerClass: 'fat-cells__table',
  },
  overflowClass: 'overflow-hidden',
};

const toggleOverflow = () => {
  const bodyClasses = document.body.classList;
  bodyClasses.toggle(CONSTANT.overflowClass);
};

//to add and remove page overflow styles
const handleHamburger = (id: string) => {
  if (id === CONSTANT.header.sibone.menuTrigger) toggleOverflow();
};

//adding dynamic height to table
const handleSiboneTable = (id: string) => {
  if (id === CONSTANT.table.sbTableTrigger) {
    const tableContainer = document.querySelector(
      `.${CONSTANT.table.sbTableContainerClass}`
    ) as HTMLDivElement;

    if (tableContainer) {
      const tableElement = tableContainer.querySelector?.(
        'table'
      ) as HTMLTableElement;

      if (tableContainer.matches('.js-toggle-on')) {
        const currHeight = tableElement?.offsetHeight;
        tableContainer.style.height = currHeight + 'px';
      } else {
        tableContainer.style.height = '0px';
      }
    }
  }
};

//Header close after click on any navlink
const handleSiboneNavLink = () => {
  const navLinks = document.querySelectorAll(
    `#${CONSTANT.header.sibone.navLinks}  a`
  );
  const menuTrigger = document.getElementById(
    CONSTANT.header.sibone.menuTrigger
  ) as HTMLButtonElement;

  if (navLinks.length) {
    navLinks?.forEach(link => {
      link.addEventListener('click', () => {
        menuTrigger?.click();
      });
    });
  }
};

(() => {
  const initSibone = () => {
    handleSiboneNavLink();

    if (window.Bus) {
      window.Bus.on('emu-button:click', ({ id }) => {
        // hamburger button click listener
        handleHamburger(id);
        //sibone page button that shows and hide table
        handleSiboneTable(id);
      });
    }
  };

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', initSibone);
  } else {
    initSibone();
  }
})();
