// Stylesheets
import './main.scss';

import './components/**/*.scss';

import './components/**/*.ts';

// import favicon here.
import './resources/images/favicon.png';

// importing Sibone page ts file
import './sibone';

// importing line modal ts file
import './linemodal';

declare global {
  interface Window {
    Bus: any; // event bus
    OneTrust: any;
    _tnsInstances: any; //carousel
    _tabbyInstances: any; //tabs
    _plyrInstances: any; //vimeo embed
    _badgerInstances: any; //accordion
  }
}

const CONSTANT = {
  header: {
    mobileNavigation: {
      menuTriggerId: 'header__menu-toggler',
    },
    header__content: 'header__content',
    search: {
      text: 'header__search-text',
      button: 'header__search-btn',
      expandButton: 'header__search-btn--expand',
    },
  },
  lineModal: {
    scaleModalClass: 'modal--scale', // Class to add to scale the modal. Will be added to modal wrapper el.
    modalWrapper: "[data-id='modal--scale'] .modal-wrapper", // Selector for modal wrapper
    closeButton: "[data-id='modal--scale'] .close", // Close button selector
    sessionTokenName: 'line-modal', // variable name in session storage
    queryToHide: 'utm_source=LINE', // Hide the line modal by css id this variable is in URL
    hideModal: 'hide-line-modal',
  },
};

(() => {
  const mobileMenuHandler = () => {
    const mobileMenuTrigger = document.getElementById(
      CONSTANT.header.mobileNavigation.menuTriggerId
    ) as HTMLButtonElement;

    const body = document.body;

    mobileMenuTrigger?.addEventListener('click', () => {
      body.classList.toggle('show-mobile-menu');
    });
  };

  // redirects user to the search page for a provided search term
  const redirectToSearchPage = (searchTerm = '') => {
    const header = document.querySelector('header.header');

    if (header) {
      const searchUrlEl = header.querySelector(
        `.${CONSTANT.header.search.text} p`
      ) as HTMLAnchorElement;
      let searchUrl = '';

      if (searchUrlEl) {
        searchUrl = searchUrlEl.innerText || '';
      }

      if (searchUrl) {
        const newSearchUrl = searchUrl + searchTerm;
        window.location.href = newSearchUrl;
      }
    }
  };

  // handles search input
  const searchHandler = () => {
    const header = document.querySelector('header.header');
    const allSearchBtn = document.querySelectorAll(
      `.${CONSTANT.header.search.button}`
    ) as NodeListOf<HTMLButtonElement>;

    if (header) {
      const searchInputs = header?.querySelectorAll(
        'input[name="SEARCH"]'
      ) as NodeListOf<HTMLInputElement>;

      // when the form with search input is submitted, redirect the user to search page
      if (searchInputs?.length) {
        searchInputs.forEach(inp => {
          const form = inp.closest?.('form');
          if (form) {
            form.addEventListener('submit', e => {
              e.preventDefault();
              const searchTerm = inp.value;
              if (searchTerm) {
                redirectToSearchPage(searchTerm);
              }
            });
          }
        });
      }

      if (allSearchBtn.length) {
        // handling search button click events
        allSearchBtn.forEach(btn => {
          btn.addEventListener('click', e => {
            e.preventDefault();
            // to find the closest input
            const input = btn?.parentElement?.parentElement?.querySelector(
              'input.cmp-form-text__text'
            ) as HTMLInputElement;
            const searchTerm = input?.value;
            if (searchTerm) {
              redirectToSearchPage(searchTerm);
            }
          });
        });
      }
    }
  };

  // makes the headers sticky when the page is scrolled.
  const makeHeaderSticky = () => {
    window.addEventListener('scroll', () => {
      // hard coded to match live site. Live also has a hard coded value
      if (window.scrollY > 78) {
        document.body.classList.add('header-sticky');
      } else {
        document.body.classList.remove('header-sticky');
      }
    });
  };

  // when window is resized, the carousel does not adjust the height automatically.
  // this custom code makes sure that the height of the carousel is adjusted when window is resized
  const adjustCarouselHeight = () => {
    const carouselInstanceKeys = window?._tnsInstances
      ? Object.keys(window?._tnsInstances)
      : null;
    if (carouselInstanceKeys) {
      window.addEventListener('resize', () => {
        carouselInstanceKeys.forEach(inst =>
          window._tnsInstances[inst]?.updateSliderHeight?.()
        );
      });
    }
  };

  // The default carousel that AAAEM Common gives stops the carousel's autoplay if the controls are interacted with
  // ABJP needs a carousel that does not pause on interactions
  // There seems to be on option available in the plugin for this.
  // This piece of code makes sure that the carousel keeps autoplaying even if the navigation dots/arrows are interacted with
  const autoplayCarousels = () => {
    const carouselInstanceKeys = window?._tnsInstances
      ? Object.keys(window?._tnsInstances)
      : null;
    if (carouselInstanceKeys) {
      carouselInstanceKeys.forEach(instKey => {
        const carouselInst = window._tnsInstances[instKey];
        const carouselInfo = carouselInst?.getInfo?.();

        if (carouselInfo) {
          const wrapper = carouselInfo.container?.closest(
            '[data-component="carousel"]'
          );
          const hasAutoplay = wrapper?.getAttribute?.('data-autoplay');
          if (hasAutoplay === 'true') {
            carouselInst.events.on('transitionEnd', () => {
              carouselInst?.play?.();
            });
          }
        }
      });
    }
  };

  const init = () => {
    makeHeaderSticky();
    mobileMenuHandler();
    searchHandler();
    adjustCarouselHeight();
    autoplayCarousels();
    if (window.Bus) {
      // listen to events here
    }
  };

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', init);
  } else {
    init();
  }
})();
