const CONSTANTS = {
  articles: {
    wrapperClass: 'article-card-list__card-wrapper',
    cardClass: 'article-card-list__card',
    imageWrapper: 'article-card-list__card-image-wrapper',
    image: 'article-card-list__card-image',
    textWrapper: 'article-card-list__card-text-wrapper',
    text: 'article-card-list__card-text',
    tagWrapper: 'article-card-list__card-tag-wrapper',
    tag: 'article-card-list__card-tag-link',
    tagPrefix: '#',
  },
  showMore: {
    selector: '[data-show-more-link]',
    selectorWrapper: 'article-card-list__action-wrapper',
    attributeName: 'data-show-more-link',
    parentContainerType: 'article-card-list',
    hide: 'article-card-list__action-wrapper--hide',
  },
  showThumbnail: false,
};

const UTILITY = {
  //Ajax get call
  ajaxGetCall: (url: string, onSuccess: any) => {
    fetch(url)
      .then(res => res.json())
      .then(res => onSuccess(res))
      .catch(err => console.error(err));
  },
};

// Defining the response structure
interface RESPONSE {
  link: string;
  poster: string;
  tags: {
    [name: string]: string;
  };
  thumbnail: string;
  title: string;
  thumbnailAltText: string;
  posterAltText: string;
}

interface RESPONSE_TEXT {
  result: [RESPONSE];
  totalItemCount: number;
}

/**
 * This function will count cards in the container
 * @param container instance where cards is to be count
 * @returns number of cards in the given container
 */
const countCardInContainer = (container: HTMLElement): number => {
  return container.querySelectorAll(`.${CONSTANTS.articles.cardClass}`).length;
};

/**
 * Construct the ajax url by adding offsets and other parameter
 * @param container need container instance to count offset
 * @param link the base link
 */
const buildShowMoreUrl = (container: HTMLElement, link: string): string => {
  const cardCount = countCardInContainer(container);
  return link + 'offset=' + cardCount;
};

/**
 * Convert each response in ajax into card
 * @param response ajax response
 */
const parseResponseToCard = (response: RESPONSE): HTMLElement | null => {
  const fragment = new DocumentFragment();

  const card = document.createElement('div');
  card.setAttribute('class', CONSTANTS.articles.cardClass);
  card.classList.add(`${CONSTANTS.articles.cardClass}--dynamically-appended`);
  fragment.appendChild(card);

  const imageAnchor = document.createElement('a');
  imageAnchor.setAttribute('href', response.link);
  imageAnchor.setAttribute('class', CONSTANTS.articles.imageWrapper);
  card.appendChild(imageAnchor);

  const image = document.createElement('img');
  image.setAttribute(
    'src',
    CONSTANTS.showThumbnail ? response.thumbnail : response.poster
  );
  image.setAttribute(
    'alt',
    CONSTANTS.showThumbnail ? response.thumbnailAltText : response.posterAltText
  );
  image.setAttribute('class', CONSTANTS.articles.image);
  imageAnchor.appendChild(image);

  const textWrapper = document.createElement('div');
  textWrapper.setAttribute('class', CONSTANTS.articles.textWrapper);
  card.appendChild(textWrapper);

  const textAnchor = document.createElement('a');
  textAnchor.setAttribute('href', response.link);
  textAnchor.setAttribute('class', CONSTANTS.articles.text);
  textWrapper.appendChild(textAnchor);

  const heading = document.createElement('h4');
  heading.innerText = response.title;
  textAnchor.appendChild(heading);

  const tagWrapper = document.createElement('div');
  tagWrapper.classList.add(CONSTANTS.articles.tagWrapper);
  Object.keys(response.tags).forEach(tag => {
    const tagLink = document.createElement('a');
    tagLink.setAttribute('class', CONSTANTS.articles.tag);
    tagLink.setAttribute('href', response.tags[tag]);
    tagLink.innerText = CONSTANTS.articles.tagPrefix + tag;
    tagWrapper.appendChild(tagLink);
  });
  textWrapper.appendChild(tagWrapper);

  return card;
};

/**
 * This function will be called when user click on show more button
 * @param container The container instance which has show more button
 * @param link from the button element which is clicked
 */
const showMoreClick = (
  container: HTMLElement,
  link: string,
  button: HTMLElement
) => {
  const cardWrapper = container.querySelector(
    `.${CONSTANTS.articles.wrapperClass}`
  );

  if (!cardWrapper) return;

  button?.setAttribute?.('disabled', 'disabled');

  UTILITY.ajaxGetCall(buildShowMoreUrl(container, link), responseText => {
    const responseObj = responseText as RESPONSE_TEXT;
    let firstCard;
    const docFrag = document.createDocumentFragment();

    // Parse the response to card and append in the container
    responseObj.result.forEach(response => {
      const card = parseResponseToCard(response as RESPONSE);
      firstCard = firstCard || card;

      if (!card) return;
      docFrag.appendChild(card);
    });

    if (docFrag?.children?.length) {
      cardWrapper.appendChild(docFrag);
    }

    // scrolling the page to make the first of appended cards appear on top of the screen
    if (firstCard) {
      const firstCardImg = firstCard.querySelector('img') as HTMLImageElement;
      if (firstCardImg?.complete) {
        firstCard?.scrollIntoView?.();
        button?.removeAttribute?.('disabled');
      } else {
        firstCardImg.addEventListener('load', () => {
          firstCard?.scrollIntoView?.();
          button?.removeAttribute?.('disabled');
        });
      }
    } else {
      button?.removeAttribute?.('disabled');
    }

    // Count the cards in the container and check if card count = max result,
    // then hide show more button
    if (countCardInContainer(container) >= responseObj.totalItemCount) {
      container
        .querySelector(`.${CONSTANTS.showMore.selectorWrapper}`)
        ?.classList.add(CONSTANTS.showMore.hide);
    }
  });
};

(() => {
  const initCardList = () => {
    // For few articles, thumbnail should be shown instead of an asset.
    // Determining whether a thumbnail must be shown, or an asset
    const showThumbnail = document.getElementById(
      'showThumbnail'
    ) as HTMLInputElement;

    if (showThumbnail) {
      CONSTANTS.showThumbnail = showThumbnail.value === 'TRUE';
    }

    // List of all show more button in the page
    const showMoreButtons = document.querySelectorAll(
      CONSTANTS.showMore.selector
    ) as NodeListOf<HTMLButtonElement>;

    if (showMoreButtons.length) {
      // Triggering show more function when user click on the valid show more button
      Array.from(showMoreButtons, button => {
        const parentContainer = button.closest(
          `[data-component="${CONSTANTS.showMore.parentContainerType}"]`
        );

        if (!parentContainer || !(parentContainer instanceof HTMLDivElement))
          return;

        if (parentContainer === null) return;

        button.addEventListener('click', () =>
          showMoreClick(
            parentContainer,
            button.getAttribute(CONSTANTS.showMore.attributeName)!,
            button
          )
        );
      });
    }
  };

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', initCardList);
  } else {
    initCardList();
  }
})();
