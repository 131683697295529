const CONSTANTS_TOC = {
  selector: '.table-of-content',
  scrollMargin: {
    desktop: 75,
    mobile: 65,
  },
  mediumWidth: 768,
};

const UTILITY_TOC = {
  isDesktop: () => window.innerWidth >= CONSTANTS_TOC.mediumWidth,
};

(() => {
  const initTable = () => {
    // if has table of content the on of any anchor tag, scroll to a section with top offset
    const tableOfContent = document.querySelector(CONSTANTS_TOC.selector);

    if (!tableOfContent || !(tableOfContent instanceof HTMLElement)) return;

    tableOfContent.querySelectorAll('a').forEach(anchor => {
      anchor.addEventListener('click', e => {
        const hash = anchor.hash;

        if (!hash) return;

        const target = document.querySelector(hash);

        if (!target || !(target instanceof HTMLElement)) return;

        // Preverting redirection if vaid hash found
        e.preventDefault();

        window.location.hash = hash;

        let offsetTop = target.offsetTop;

        if (UTILITY_TOC.isDesktop()) {
          offsetTop -= CONSTANTS_TOC.scrollMargin.desktop;
        } else {
          offsetTop -= CONSTANTS_TOC.scrollMargin.mobile;
        }

        window.scrollTo({
          top: offsetTop,
          left: 0,
          behavior: 'smooth',
        });
      });
    });
  };

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', initTable);
  } else {
    initTable();
  }
})();
