(() => {
  const CONSTANTS = {
    scaleModalClass: 'modal--scale', // Class to add to scale the modal. Will be added to modal wrapper el.
    modalWrapper: "[data-id='modal--scale'] .modal-wrapper", // Selector for modal wrapper
    closeButton: "[data-id='modal--scale'] .close", // Close button selector
    sessionTokenName: 'line-modal', // variable name in session storage
    hideModal: 'hide-line-modal',
    modalClass: 'line-modal',
  };

  // LINE MODAL START
  const lineModalInit = () => {
    const lineModal = document.querySelector('.' + CONSTANTS.modalClass);

    if (lineModal) {
      const modalTimer = Number(
        lineModal.getAttribute('data-show-on-load-delay') || 0
      );
      const closeButton = document.querySelector(CONSTANTS.closeButton)!;
      const modalWrapper = document.querySelector(CONSTANTS.modalWrapper);

      // When clicking apart from close button, the modal should scale and blink
      modalWrapper?.addEventListener('click', event => {
        if (
          !(event.target instanceof Element) ||
          closeButton.contains(event.target)
        ) {
          return;
        }

        modalWrapper.addEventListener(
          'animationend',
          () => {
            modalWrapper.classList.remove(CONSTANTS.scaleModalClass);
          },
          { once: true }
        );

        modalWrapper.classList.add(CONSTANTS.scaleModalClass);
      });

      // hides line modal when called
      const hideLineModal = (): void => {
        document
          .getElementsByTagName('body')[0]
          .classList.add(CONSTANTS.hideModal);
      };

      // check and returns whether the session storage has the line modal related entry
      const checkSessionStorage = (): boolean => {
        const currentValue = sessionStorage.getItem(CONSTANTS.sessionTokenName);

        if (!currentValue) return false;

        return currentValue === 'true';
      };

      /**
       * DO NOT SHOW THE LINE MODAL
       * 1. if sessionStorage already has line modal related entry (Will be added once the modal is open)
       * 2. if the URL has utm_source=true (As per the ticket https://allerganbt.atlassian.net/browse/ABJ-430)
       * 3. in the AEM author mode
       */
      const utmParam = new URLSearchParams(window.location.search)
        ?.get('utm_source')
        ?.toLowerCase?.();
      if (
        utmParam === 'line' ||
        checkSessionStorage() ||
        document.querySelector('.cq-Editable-dom--container')
      ) {
        hideLineModal();
        return;
      }

      /**
       * When the line modal is opened, add an entry to the session storage to prevent showing line modal further
       * NOTE: AAAEM is not offering an open event on bus
       */
      if (modalTimer && isNaN(modalTimer) !== true) {
        const timer = setTimeout(() => {
          sessionStorage.setItem(CONSTANTS.sessionTokenName, 'true');
          clearTimeout(timer);
        }, modalTimer * 1000);
      }
    }
  };
  // LINE MODAL END

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', lineModalInit);
  } else {
    lineModalInit();
  }
})();
